import { type Routes, type RouteParamsWeb } from '@medely/ui-kit';
import { NumberParam, StringParam } from 'use-query-params';

// Key = Route name
// Value = Params type
// Optional params should use | undefined, not ?
// Currently only primitive values are supported.
export type ProRoutes = {
  AdjustmentDetail: {
    id: number;
  };
  Application: never;
  AvailableAssignmentsLocal: never;
  EarningsPayoutDetails: {
    id: number;
  };
  EarningsPending: never;
  JobDetails: {
    id: number;
  };
  Profile: never;
  ProPoolInvite: {
    locationId: number;
    facilityName: string;
    professionalQualificationId: number;
    plan: string;
  };
  ProPoolInviteAccepted: {
    facilityName: string;
  };
  LocationOnboarding: {
    location_id: number;
  };
  Today: never;
  PositionsAndSpecialties: never;
  PayoutTaxes: never;
  Credentials: never;
  RootCredentials: never;
  AccountDetails: { id: number | string };
  BackgroundCheckDisclosure: never;
  PayoutMethod: Partial<{ id: number | string }>;
  TaxInformation: Partial<{ id: number | string }>;
  MissingAndExpiringConditions: never;
};

export const proRoutes: Routes<ProRoutes> = {
  AdjustmentDetail: {
    url: '/earnings/adjustments/:id',
  },
  Application: {
    url: '/application',
  },
  AvailableAssignmentsLocal: {
    url: '/available/assignments/local',
  },
  EarningsPayoutDetails: {
    url: '/earnings/payouts/:id',
  },
  EarningsPending: {
    url: '/earnings/pending',
  },
  JobDetails: {
    url: '/jobs/:id',
  },
  Profile: {
    url: '/profile',
  },
  ProPoolInvite: {
    url: '/pro-pool/:locationId/invite',
  },
  ProPoolInviteAccepted: {
    url: '/pro-pool/invite-accepted',
  },
  LocationOnboarding: {
    url: 'onboarding/location/:location_id',
  },
  Today: {
    url: '/',
  },
  PositionsAndSpecialties: {
    url: '/profile/positions-and-specialties',
  },
  PayoutTaxes: {
    url: '/settings/financial',
  },
  AccountDetails: {
    url: '/settings/financial/:id/details',
  },
  BackgroundCheckDisclosure: {
    url: '/onboarding/background_check/disclosure',
  },
  PayoutMethod: {
    url: '/settings/financial/payout-method',
  },
  TaxInformation: {
    url: '/settings/financial/taxinfo',
  },
  Credentials: {
    url: '/credentials',
  },
  RootCredentials: {
    url: '/RootCredentials',
  },
  MissingAndExpiringConditions: {
    url: '/credentials/status',
  },
};

export const proQueryParams: RouteParamsWeb<ProRoutes> = {
  AdjustmentDetail: {
    id: NumberParam,
  },
  Application: {},
  AvailableAssignmentsLocal: {},
  EarningsPayoutDetails: {
    id: NumberParam,
  },
  EarningsPending: {},
  JobDetails: {
    id: NumberParam,
  },
  Profile: {},
  ProPoolInvite: {
    locationId: NumberParam,
    facilityName: StringParam,
    professionalQualificationId: NumberParam,
    plan: StringParam,
  },
  ProPoolInviteAccepted: {
    facilityName: StringParam,
  },
  LocationOnboarding: {
    location_id: NumberParam,
  },
  Today: {},
  PayoutTaxes: {},
  TaxInformation: {},
  AccountDetails: {},
  BackgroundCheckDisclosure: {},
  Credentials: {},
  MissingAndExpiringConditions: {},
  PayoutMethod: {},
  PositionsAndSpecialties: {},
  RootCredentials: {},
};
