import {
  CredentialStatus,
  ICondition,
  ICredentialRejectedReason,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
} from '@medely/types';
import {
  QueryFunctionContext,
  QueryObserverResult,
  UseMutateFunction,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'utils/networkRequests';
import { GET_PROFESSIONAL_QUALIFICATIONS } from '@mp';
import useMissingConditions from './useMissingConditions';
import useCurrentUser from './useCurrentUser';
import {
  useEditProfessionalQualification,
  useSubmitPendingProfessionalQualification,
  useSubmitProfessionalQualification,
} from './useProfessionalQualification';
import useGraphQLRequest from './useGraphQLRequest';
import { DEFAULT_REFETCH_INTERVAL } from 'constants/graphql';
import { ConditionSatisfactionHelper } from '@medely/credentials-tools';

export const VIEWABLE_PQ_STATUSES: CredentialStatus[] = [
  'approved',
  'rejected',
  'expired',
  'review',
  'incomplete',
  // @ts-expect-error TODO: remove after merging https://github.com/Medely/medely-core-packages/pull/1156
  'incomplete_pending_review',
];

const DOCUMENT_TYPES = ['document', 'signed_document'];

// Same as deprecated ICredential, the purpose is to temporarily support the made up TB Qualification.
type TbProfessionalQualification = {
  id: number;
  qualification: Partial<IQualification>;
  status: CredentialStatus;
  professional_qualification_attributes: Partial<IProfessionalQualificationAttribute>[];
  credential_rejected_reason: Partial<ICredentialRejectedReason>;
};

export type CombinedTbProfessionalQualification =
  | IProfessionalQualification
  | TbProfessionalQualification;

export type TbScreening = {
  tbScreening: TbProfessionalQualification;
  tbBaseline: IProfessionalQualification;
  tbQuestionnaire: IProfessionalQualification;
};

type ProfessionalQualifications = {
  licenses: IProfessionalQualification[];
  missingLicenses: ICondition[];
  certifications: CombinedTbProfessionalQualification[];
  missingCertifications: ICondition[];
  documents: IProfessionalQualification[];
  missingDocuments: ICondition[];
  allProfessionalQualifications: IProfessionalQualification[];
  allMissingConditions: ICondition[];
  hasMissingCredentials: boolean;
  facilityOnboarding: IProfessionalQualification;
  missingFacilityOnboarding: ICondition;
  completedFacilityOnboarding: boolean;
  isLoading: boolean;
  submitProfessionalQualification: UseMutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  submitPendingProfessionalQualification: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
  editProfessionalQualification: UseMutateFunction<AxiosResponse<any>, unknown, any, unknown>;
  refetch: () => Promise<QueryObserverResult<IProfessionalQualification[], unknown>>;
};

export const useFetchProfessionalQualifications = () => {
  const { request } = useGraphQLRequest();

  const fetchProfessionalQualifications = async ({
    queryKey: [_entity, search],
  }: Partial<QueryFunctionContext<[unknown, object?]>>): Promise<IProfessionalQualification[]> => {
    const { professionalQualifications } = await request(GET_PROFESSIONAL_QUALIFICATIONS, {
      input: { search: { statuses: VIEWABLE_PQ_STATUSES, not_archived: true, ...(search ?? {}) } },
    });
    return professionalQualifications;
  };

  return fetchProfessionalQualifications;
};

export type UseProfessionalQualificationsArgs = {
  job_id?: number;
  position_ids?: number[];
  refetch?: boolean;
};

const useProfessionalQualifications = ({
  job_id,
  position_ids,
  refetch,
}: UseProfessionalQualificationsArgs = {}): ProfessionalQualifications => {
  const { currentUser } = useCurrentUser();

  const enabled = !!currentUser;

  const { edit, isEditing } = useEditProfessionalQualification();
  const { submit, isSubmitting } = useSubmitProfessionalQualification();
  const { submitPending, isSubmittingPending } = useSubmitPendingProfessionalQualification();

  const { conditions: missingConditions } = useMissingConditions({ job_id, position_ids, refetch });

  const fetchProfessionalQualifications = useFetchProfessionalQualifications();
  const {
    data: currentProfessionalQualifications = [],
    isInitialLoading: fetching,
    refetch: refetchProfessionalQualifications,
  } = useQuery(['professionalQualifications'], fetchProfessionalQualifications, {
    enabled,
    refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
  });

  const credentialDetails = parseConditionsAndQualifications(
    missingConditions,
    currentProfessionalQualifications,
    currentUser?.professional?.satisfied_condition_ids ?? [],
  );

  return {
    ...credentialDetails,
    allMissingConditions: missingConditions,
    allProfessionalQualifications: currentProfessionalQualifications,
    completedFacilityOnboarding:
      !!credentialDetails.facilityOnboarding &&
      credentialDetails.facilityOnboarding.status === 'approved',
    editProfessionalQualification: edit,
    isLoading: fetching || isSubmitting || isEditing || isSubmittingPending,
    submitProfessionalQualification: submit,
    submitPendingProfessionalQualification: submitPending,
    refetch: refetchProfessionalQualifications,
  };
};

const groupProfessionalQualifications = (
  currentProfessionalQualifications: IProfessionalQualification[],
) => {
  const result = {
    certifications: [],
    documents: [],
    signedDocuments: [],
    facilityOnboarding: undefined,
    licenses: [],
  };

  currentProfessionalQualifications.forEach((pq) => {
    if (pq.qualification?.qualification_type === 'license') {
      result.licenses.push(pq);
      return;
    }

    if (['certification', 'q_and_a'].includes(pq.qualification?.qualification_type)) {
      result.certifications.push(pq);
      return;
    }

    if (DOCUMENT_TYPES.includes(pq.qualification.qualification_type)) {
      result.documents.push(pq);
      return;
    }

    if (pq.qualification.qualification_type === 'facility_onboarding') {
      result.facilityOnboarding = pq;
      return;
    }
  });

  return result;
};

const groupMissingConditions = (
  missingConditions: ICondition[],
  currentProfessionalQualifications: IProfessionalQualification[],
  currentSatisfiedConditionIds: number[],
) => {
  const result = {
    hasMissingCredentials: false,
    missingCertifications: [],
    missingCredentials: [],
    missingDocuments: [],
    missingLicenses: [],
    missingFacilityOnboarding: undefined,
  };

  missingConditions.forEach((condition) => {
    if (condition.qualifications && condition.qualifications[0].qualification_type === 'license') {
      result.missingLicenses.push(condition);
      return;
    }

    if (
      condition.qualifications &&
      condition.qualifications[0].qualification_type === 'certification'
    ) {
      result.missingCertifications.push(condition);
      return;
    }

    if (
      condition.qualifications &&
      DOCUMENT_TYPES.includes(condition.qualifications[0].qualification_type)
    ) {
      result.missingDocuments.push(condition);
      return;
    }

    if (condition.condition_type === 'facility_onboarding') {
      result.missingFacilityOnboarding = condition;
      return;
    }
  });

  // pro has missing conditions which will not be satisfied by the current professional qualifications they have uploaded
  const missingCredentials = [
    ...result.missingLicenses,
    ...result.missingCertifications,
    ...result.missingDocuments,
  ].filter((condition) => {
    const conditionHelper = new ConditionSatisfactionHelper({
      condition,
      professionalQualifications: currentProfessionalQualifications,
      satisfiedConditionIds: currentSatisfiedConditionIds,
    });
    return !conditionHelper.isAwaitingReview;
  });

  result.hasMissingCredentials = !!missingCredentials.length;

  return result;
};

const parseConditionsAndQualifications = (
  missingConditions: ICondition[],
  currentProfessionalQualifications: IProfessionalQualification[],
  currentSatisfiedConditionIds: number[],
) => {
  return {
    ...groupMissingConditions(
      missingConditions,
      currentProfessionalQualifications,
      currentSatisfiedConditionIds,
    ),
    ...groupProfessionalQualifications(currentProfessionalQualifications),
  };
};

export default useProfessionalQualifications;
