import {
  Box,
  Heading,
  HStack,
  Text,
  SingleSelectField,
  TextField,
  VStack,
  Link,
} from '@medely/ui-kit';
import { FixedFooterLayout } from '@mp/components/FixedFooterLayout';
import React from 'react';
import { useTaxInfoTab } from './useTaxInfoTab';
import { useFormContext } from 'react-hook-form';
import type { IStripeAccount } from '@medely/types';
import type { TaxInfo } from '@mp/models/settings/tax-info/TaxInfo.schema';
import { TaxInfoFooter } from './components/TaxInfoFooter';
import { useIsMutating } from '@tanstack/react-query';
import { TaxInfoBusinessLegalRepresentativeInputs } from './components/TaxInfoBusinessLegalRepresentativeInputs';
import type { NotificationT } from './types';
import { config } from '@mp/config';

type TaxInfoFormProps = {
  account: IStripeAccount | null;
  notification: NotificationT;
  isIndividual: boolean;
};

export const TaxInfoForm = ({ account, notification, isIndividual }: TaxInfoFormProps) => {
  const { taxClassificationOptions, stateOptions, onSubmit, handleGoBack } = useTaxInfoTab({
    account,
    notification,
  });
  const { handleSubmit } = useFormContext<TaxInfo>();
  const isMutating = useIsMutating();

  const isEditing = !!account?.id;

  return (
    <FixedFooterLayout
      footerContent={
        <TaxInfoFooter
          saveAction={() => handleSubmit(onSubmit)()}
          cancelAction={() => handleGoBack()}
          isLoading={!!isMutating}
        />
      }
    >
      <VStack gap={2}>
        {isIndividual ? (
          <>
            <TextField label="First Name" name="first_name" />
            <TextField label="Last Name" name="last_name" />
            <TextField label="SSN" name="personal_id_number" />
          </>
        ) : (
          <>
            {!isEditing && (
              <Box>
                <HStack my={1} alignItems="center" flexWrap="wrap" gap={0.5}>
                  <Text size="s">
                    Select &apos;Private Corporation&apos; if your single-member LLC is taxed as a
                    corporation.
                  </Text>
                  <Link url={config.help.stripeRepresentativeTaxInfo} variant="text" size="s">
                    Learn more
                  </Link>
                </HStack>

                <SingleSelectField
                  label="Tax classification"
                  name="tax_classification_id"
                  options={taxClassificationOptions}
                />
              </Box>
            )}

            <TextField label="Legal business name" name="company_name" />

            <TextField
              label="EIN"
              name="personal_id_number"
              formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
            />
          </>
        )}

        <Box mt={2}>
          <Heading size="xs" testId="tax-info-home-address-title">
            Address
          </Heading>

          <Text size="s">The primary location where business activity is conducted.</Text>
        </Box>

        <TextField
          label="Street Address"
          name="address_first"
          testId="tax-info-home-address-first"
        />

        <HStack gap={1}>
          <Box flex={1}>
            <TextField
              label="Address 2"
              name="address_second"
              testId="tax-info-home-address-second"
            />
          </Box>

          <Box flex={1}>
            <TextField label="City" name="address_city" testId="tax-info-home-address-city" />
          </Box>
        </HStack>

        <HStack gap={1}>
          <Box flex={1}>
            <SingleSelectField label="State" name="state_id" options={stateOptions} />
          </Box>

          <Box flex={1}>
            <TextField
              label="ZIP Code"
              name="address_zip"
              testId="tax-info-home-address-state"
              formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
            />
          </Box>
        </HStack>
        {!isIndividual && <TaxInfoBusinessLegalRepresentativeInputs stateOptions={stateOptions} />}
      </VStack>
    </FixedFooterLayout>
  );
};
